<script>
  import LogTable from '@cox2m/city-services-ui-components/src/components/monitoring/LogTable.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';

  import {menuOpts} from '../../stores';
</script>

<style>
  .dashboard-component-container {
    background-color: var(--cox2m-clr-neutral-white);
    border-radius: 16px;
    padding: var(--cox2m-spacing-8-units) 0;
    min-height: 100%;
    box-shadow: var(--cox2m-shadow-3-dp-dir) var(--cox2m-shadow-3-dp-clr);
  }
</style>

<MainScreen title="logs" menuOpt={$menuOpts}>
  <div slot="main-dashboard" class="dashboard-component-container">
    <LogTable appSlug="economic-redevelopment" />
  </div>
</MainScreen>
