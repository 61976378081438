import CurbsideStatusMonitoring from './forms/monitoring/CurbsideStatusMonitoring.svelte';
import AudienceTrafficAnalytics from './forms/AudienceTrafficAnalytics.svelte';
import MonitoringRedirect from './forms/monitoring/MonitoringRedirect.svelte';
import AlertsWrapper from './forms/alerts/AlertsWrapper.svelte';
import Monitoring from './forms/monitoring/Monitoring.svelte';

import NotFoundPage from './forms/NotFoundPage.svelte';

const routes = {
  '/analytics/:page?': AudienceTrafficAnalytics,
  '/alerts': AlertsWrapper,

  '/monitoring': MonitoringRedirect,
  '/monitoring/log-table': Monitoring,
  '/monitoring/status': CurbsideStatusMonitoring,

  '*': NotFoundPage,
};

export const openRoutes = []

export default routes

