import {getPowerBiReportPages, findByKeyRecursively} from '@cox2m/city-services-ui-components/src/funcs';

export const staticMenuOptions = user => [
  {
    key: 'home',
    name: 'Home',
    icon: 'dashboard',
    path: 'SMART_CITIES_URL',
    externalRoute: true,
    shouldShow: true
  },
  {
    key: 'economic-dev',
    name: 'Economic dev',
    icon: 'trend-up',
    shouldShow: true,
    subItems: [
      {
        key: 'analytics-overview',
        name: 'Overview',
        icon: 'dashboard',
        shouldShow: true
      },
    ]
  },
  {
    key: 'monitoring',
    name: 'Monitoring',
    icon: 'chart-line',
    shouldShow:
      user &&
      user.operations &&
      user.operations.includes('get-events-using-filter'),
    subItems: [
      {
        key: 'log',
        path: '/monitoring/log-table',
        name: 'Logs',
        icon: 'devices',
        shouldShow: true
      },
      {
        key: 'status',
        path: '/monitoring/status',
        name: 'Curbside Status',
        icon: 'activity',
        shouldShow: true
      },
    ]
  },
  {
    key: 'alerts',
    path: '/alerts',
    name: 'Alerts+Notifications',
    icon: 'gear-2',
    shouldShow:
      user &&
      user.operations &&
      user.operations.includes('get-notifications-metrics-by-app-id')
  },


  {
    key: 'logout',
    path: 'SMART_CITIES_URL/#/logout',
    name: 'Log out',
    icon: 'log-out',
    shouldShow: true,
    bottomOption: true,
    externalRoute: true
  }
];

export const buildMenuWithDynamicItems = async (user, TOKEN) => {
  let tempMenuOptions = staticMenuOptions(user);

  try {
    const response = await getPowerBiReportPages({
      workspaceId: '2a9d04e1-3229-4343-87ce-8519e3d93747',
      reportId: 'fab18b23-e7f1-472e-b69b-ada557bbcfe4',
      token: TOKEN
    });

    let analyticsEntry = findByKeyRecursively({
      searchCriteria: "analytics-overview",
      array: tempMenuOptions || [],
    });

    analyticsEntry.subItems = response.value.map(page => ({
      key: `analytics-page-${page.order}`,
      path: `/analytics/${encodeURI(page.displayName).replace('/', '-')}`,
      name: page.displayName,
      icon: 'clipboard-text',
      shouldShow: true
    }));
  } catch (e) {
    console.error(e);
  }

  return tempMenuOptions;
};
