<script>
  import PowerBiReportWrapper from '@cox2m/city-services-ui-components/src/components/PowerBiReportWrapper.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';
  import Unauthorized from '../modals/Unauthorized.svelte';

  import {
    getCookieByName,
    shouldDisplayUnauthorizedModal
  } from '@cox2m/city-services-ui-components/src/funcs';
  import {menuOpts} from '../stores';

  export let params;

  let pageName;
  let reportPages;

  const token = getCookieByName(`${'ENV'}_token`);

  let modalContext;

  const setPage = async pageName => {
    if (pageName && reportPages) {
      const page = reportPages.find(page => page.displayName === pageName);
      page && (await page.setActive());
    }
  };

  const handleOnLoad = async e => {
    let report;

    if (e && e.detail.report) {
      report = e.detail.report;
      reportPages = await report.getPages();
      setPage(pageName);
    }
  };

  $: pageName = decodeURI(params.page).replace('-', '/') || null;
  $: setPage(pageName);
</script>

<Modal bind:this={modalContext}>
  <MainScreen title="Home - Analytics" menuOpt={$menuOpts}>
    <div slot="main-dashboard" class="h-100">
      <PowerBiReportWrapper
        reportId="fab18b23-e7f1-472e-b69b-ada557bbcfe4"
        workspaceId="2a9d04e1-3229-4343-87ce-8519e3d93747"
        datasetIds="2fa49320-2aea-477e-9deb-172d6d401ffa"
        {token}
        enablePageNavigation={false}
        shouldDisplayUnauthorizedModal={() => shouldDisplayUnauthorizedModal(modalContext, Unauthorized)}
        on:load={handleOnLoad} />
    </div>
  </MainScreen>
</Modal>
